import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/home';

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/member",
    },
    {
      path: "",
      name: "home",
      component: home,
      children: [
        {
          path: "/member",
          name: "member",
          component: () => import("@/views/member"),
        },
        {
          path: "/company",
          name: "company",
          component: () => import("@/views/company"),
        },
        {
          path: "/community",
          name: "community",
          component: () => import("@/views/community"),
        },
      ],
    },
  ],
});
export default router
